import router from "./router";
// import store from './store'
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
// import { getToken } from '@/utils/auth'
// import { isRelogin } from '@/utils/request'
import request from "@/utils/request";
// NProgress.configure({ showSpinner: false })

// const whiteList = ['/login', '/register']

router.beforeEach((to, from, next) => {
  console.log(to.query);
  const userToken = to.query["cmd.USERID"];
  // const userToken = to.query.LoginName;
  console.log(userToken);
  if (!userToken && !getUserTokenHeader()) {
    // Message.error("获取登录信息失败,请刷新重试");
  } else if (userToken) {
    setUserTokenHeader(userToken);
  }
  next();
});

// router.afterEach(() => {
//   NProgress.done()
// })

// 获取路由
function setUserTokenHeader(userToken) {
  return (request.defaults.headers["userToken"] = userToken);
}

// 获取路由
function getUserTokenHeader() {
  return request.defaults.headers["userToken"];
}
